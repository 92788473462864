import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {

  constructor(private http:HttpClient) { }
  erpenquiry = { 'name': '', 'message': '',  'mobileno': '','mailid':'','status':'New Enquiry','source':'Partners' };
  ngOnInit(): void {
  }
  submitSend() {
   
    let api = this.http.post('https://api.valaischool.com/api/Admin/inserterpenquiry',
      {
        
        'name': this.erpenquiry.name,
        'mobileno': this.erpenquiry.mobileno,
        'message': this.erpenquiry.message,
        'mailid': this.erpenquiry.mailid, 
        'status': this.erpenquiry.status, 
        'source': this.erpenquiry.source,    
        'mode': "INSERT"
      })
      .subscribe((data) => {

        if (data) {
          window.alert('Thank you for your interest in our service. Someone from our team will reach you soon');

          
        } else {
          window.alert('Unable to save the record!');
          
        }

       
      },

      error => {
          console.log('Log the error here: ', error);
      });

  //  this.subscriptions.push(api);
  };
}
