<div class="header-container">
    <div class="header-wrapper">
        <div class="logo">
            <a href="/home">
                <img src="./../../assets/img/Logo.png" />
            </a>
        </div>
        <div class="nav-links">
            <nav>
                <ul>
                    <li> <a href="/features"> Features </a> </li>
                    <li> <a href="/pricing"> Pricing </a> </li>
                    <!-- <li> <a href="/signup"> Registration </a> </li> -->
                    <li> <a href="/partners"> Partners </a> </li>
                    <li> <a href="/contact"> Contact </a> </li>
                    <li> <a href="http://login.valaischool.com/auth/login"> Login </a> </li>
                </ul>
            </nav>
        </div>
        <div class="btn-container">
            <button>
                <a href="/signup">Free Registration </a></button>
        </div>
    </div>
</div>


<div class="topnav">
    <a href="/home" class="logo">
        <img src="./../../assets/img/Logo.png" />
    </a>
    <div id="myLinks">
        <a href="/features"> Features </a>
        <a href="/pricing"> Pricing </a>
        <a href="/signup"> Registration </a>
        <a href="/partners"> Partners </a>
        <a href="/contact"> Contact </a>
        <a href="http://login.valaischool.com/auth/login"> Login </a>
    </div>

    <a href="javascript:void(0);" class="icon" (click)="toggleHanburger()">
      <i class="fa fa-bars"></i>
    </a>
  </div>
