<div class="privacy">
    <h2>Cancellation Policy</h2>
        <p>1. Cancellations are considered under specific conditions if requested within 2 days of payment, subject to the school's approval. The school reserves the right to assess and approve cancellations based on their policies.</p>
    
        <p>2. In cases of double payment due to technical issues, parents have the option to request a refund, subject to transaction costs, or settle the ward's upcoming fees invoices against the payment.</p>
    
        <p>3. Customers must promptly communicate cancellation requests to ensure timely processing.</p>
    
        <p>4. Similar to refunds, parents have the choice to either receive a refund (with applicable transaction costs) or use the extra payment to offset future fees.</p>
    
        <p>5. Cancellation requests will be processed within a specific timeframe, and customers will be notified of the status accordingly.</p>
    
        <p>6. Exceptions to the standard cancellation policy may be considered in exceptional circumstances, and customers can inquire about such cases.</p>
    
        <p>7. Cancellations for registrations made during discounted or promotional campaigns may be subject to specific terms, and customers should review these conditions.</p>
    
        <p>8. Customers are responsible for ensuring that their cancellation requests meet the specified criteria and include all required documentation.</p>
    
        <p>9. We rely on a third-party payment service provider for our online gateway. Neither we nor our provider assume liability for any damage, monetary or otherwise, resulting from delays, interruptions, corruption, or transmission failures during payment. Chargebacks occur only if we fail to deliver services after payment. Customers should be aware of these aspects before using our payment services.</p>
    
        <p>10. For any questions or doubts about the Refunds & Cancellations policy, customers can contact the company at sales@evalaischool.com.</p>
</div>