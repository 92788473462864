<div class="privacy">
    <h2>Cancellation & Refund Policy</h2>
    <p>
        1. Refunds to be requested within 30 days of payment.
    </p>
    <p>
        2.  Refunds will only be made to the same account or card from which the payments were made.
    </p>
    <p>
        3. Refunds are subject to a thorough verification process related to the account holder's credentials.
    </p>
    <p>
        4. Only online refunds are offered; no cheques or cash refunds are entertained.
    </p>
    <p>
        5. Any extra charges borne by the customer, such as bank transaction fees, service fees, or processing fees, will not be refunded.
    </p>
    <p>
        6. No interest is paid on refunds.
    </p>
    <p>
        7. The customer is responsible for ensuring accurate and complete contact details to receive communications regarding refunds.
    </p>
    <p>
        8. We rely on a third-party payment service provider for our online gateway. Neither we nor our provider assume liability for any damage, monetary or otherwise, resulting from delays, interruptions, corruption, or transmission failures during payment. Chargebacks occur only if we fail to deliver services after payment. Customers should be aware of these aspects before using our payment services.
    </p>
    <p>
        9.  For any questions or doubts about the Refunds & Cancellations policy, customers can contact the company at inquiry@evalai.com
    </p>
    
   
</div>