<div class="pricing-container">
  <section class="pricing-header">
    <h2>
      Pricing Plans
    </h2>
    <p>
      School ERP pricing plans for any school size. No hidden charges.
    </p>
  </section>


  <div class="pricing-banner">
    <div class="banner">
        <div class="header">
          Silver
        </div>

        <div class="price-container">
          <div class="price">
            <span class="dollar">
              ₹
            </span>
            3
          </div>
          <div class="duration">
            Monthly
          </div>
          
        </div>

        <div class="pricing-breakup">
          <div class="breakup-container">
            <div class="icon">
              <mat-icon class="material-icons-outlined"> check_circle </mat-icon>
            </div>
            <div class="details">STUDENT MODULE</div>
          </div>
          
          <div class="breakup-container">
            <div class="icon">
              <mat-icon class="material-icons-outlined"> check_circle </mat-icon>
            </div>
            <div class="details"> FEE MODULE</div>
          </div>

          <div class="breakup-container">
            <div class="icon">
              <mat-icon class="material-icons-outlined"> check_circle </mat-icon>
            </div>
            <div class="details"> CIRCULAR AND ASSIGNMENT </div>
          </div>

          <div class="breakup-container">
            <div class="icon">
              <mat-icon class="material-icons-outlined"> check_circle </mat-icon>
            </div>
            <div class="details"> VALAISCHOOL APP </div>
          </div>

          <div class="breakup-container">
            <div class="icon">
              <mat-icon class="material-icons-outlined"> check_circle </mat-icon>
            </div>
            <div class="details"> UNLIMITED PUSH NOTIFICATIONS</div>
          </div>

        </div>

        <button> 15 DAYS FREE TRIAL </button>
    </div>

    <div class="banner">
      <div class="header">
        Gold
      </div>

      <div class="price-container">
        <div class="price">
          <span class="dollar">
            ₹
          </span>6
        </div>
        <div class="duration">
          Monthly
        </div>
        
      </div>

      <div class="pricing-breakup">
        <div class="breakup-container">
          <div class="icon">
            <mat-icon class="material-icons-outlined"> check_circle </mat-icon>
          </div>
          <div class="details">TIMETABLE MGMT</div>
        </div>
        
        

        <div class="breakup-container">
          <div class="icon">
            <mat-icon class="material-icons-outlined"> check_circle </mat-icon>
          </div>
          <div class="details"> ID CARD/HALL TICKET/ TC / BONEFIDE/STUDY CERTIFICATE</div>
        </div>
        

        <div class="breakup-container">
          <div class="icon">
            <mat-icon class="material-icons-outlined"> check_circle </mat-icon>
          </div>
          <div class="details"> HR & PAYROLL </div>
        </div>

        <div class="breakup-container">
          <div class="icon">
            <mat-icon class="material-icons-outlined"> check_circle </mat-icon>
          </div>
          <div class="details"> TRANSPORT  & SMS </div>
        </div>

        <div class="breakup-container">
          <div class="icon">
            <mat-icon class="material-icons-outlined"> check_circle </mat-icon>
          </div>
          <div class="details">STUDENT & STAFF ATTENDANCE </div>
        </div>


      </div>

      <button> 15 DAYS FREE TRIAL
</button>
    </div>


    <div class="banner">
      <div class="sache">
        popular
      </div>
      <div class="header">
        PLATINUM
      </div>

      <div class="price-container">
        <div class="price">
          <span class="dollar">
            ₹
          </span>10
        </div>
        <div class="duration">
          Monthly
        </div>
       
      </div>

      <div class="pricing-breakup">
        <div class="breakup-container">
          <div class="icon">
            <mat-icon class="material-icons-outlined"> check_circle </mat-icon>
          </div>
          <div class="details">REPORT CARD
          </div>
        </div>
        
        <div class="breakup-container">
          <div class="icon">
            <mat-icon class="material-icons-outlined"> check_circle </mat-icon>
          </div>
          <div class="details">APP IN SCHOOL NAME
          </div>
        </div>

        <div class="breakup-container">
          <div class="icon">
            <mat-icon class="material-icons-outlined"> check_circle </mat-icon>
          </div>
          <div class="details"> CUSTOMIZED CERTIFICATES
          </div>
        </div>

        <div class="breakup-container">
          <div class="icon">
            <mat-icon class="material-icons-outlined"> check_circle </mat-icon>
          </div>
          <div class="details"> LEARNING MANAGEMENT SYSTEM
          </div>
        </div>
        <div class="breakup-container">
          <div class="icon">
            <mat-icon class="material-icons-outlined"> check_circle </mat-icon>
          </div>
          <div class="details"> BIOMETRIC DEVICE INTEGRATION </div>
        </div>

        
      </div>

      <button> 15 DAYS  FREE TRIAL
      </button>
    </div>


    <div class="banner">
      <div class="header">
        ENTERPRISE

      </div>

      <div class="price-container">
        <div class="price">
          
          Call us

        </div>
        <div class="duration">
          9845926911
        </div>
        
      </div>

      <div class="pricing-breakup">
        <div class="breakup-container">
          <div class="icon">
            <mat-icon class="material-icons-outlined"> check_circle </mat-icon>
          </div>
          <div class="details">CHAIN OF SCHOOLS ?
          </div>
        </div>
        
        <div class="breakup-container">
          <div class="icon">
            <mat-icon class="material-icons-outlined"> check_circle </mat-icon>
          </div>
          <div class="details"> MORE THAN 1000 STUDENTS ?
          </div>
        </div>

        <div class="breakup-container">
          <div class="icon">
            <mat-icon class="material-icons-outlined"> check_circle </mat-icon>
          </div>
          <div class="details"> NEED INHOUSE TRAINING ?
          </div>
        </div>

        <div class="breakup-container">
          <div class="icon">
            <mat-icon class="material-icons-outlined"> check_circle </mat-icon>
          </div>
          <div class="details"> NEED LMS CONTENTS ?
          </div>
        </div>

        <div class="breakup-container">
          <div class="icon">
            <mat-icon class="material-icons-outlined"> check_circle </mat-icon>
          </div>
          <div class="details"> NEED CUSTOMIZED SOLUTION ?
          </div>
        </div>



      </div>

      <button> 15 DAYS  FREE TRIAL </button>
    </div>  
  </div>


  <div class="sign-up-free-bg">
    <section class="sign-up-free-container">
      <div>Select the plan that suits your needs. We give user manual and training videos apart from online Training.
        And for non-pofits and charity
        schools, there is a 20% discount on valai Pro.
      </div>

      <button>SIGNUP FOR FREE</button>
    </section>
  </div>

  <div class="pricing-footer-bg">
    <section class="pricing-footer-container">
      <h2>Best School ERP Pricing Plans</h2>
      <p>We will get you started with valai as your <a>student information system</a>, starting from free setup to
        assisting
        you in migrating your existing data. We will even train your staff for the yearly leased license.
      </p>
    </section>

    <section class="pricing-features-container">
      <section>
        <p>
          <mat-icon>
            check_circle
          </mat-icon>
          Free or Paid version, we will do all initial configuration
        </p>
        <p>
          <mat-icon>
            check_circle
          </mat-icon>
          Friendly Support
        </p>
      </section>

      <section>
        <p>
          <mat-icon>
            check_circle
          </mat-icon>
          Special Pricing for chains and schools with 1000+ students
        </p>
        <p>
          <mat-icon>
            check_circle
          </mat-icon>
          Special Pricing for Non Profits
        </p>
      </section>
    </section>
  </div>
</div>
