<div class="partner-container">
  <div class="partner-wrapper">
    <h2>Partners Resellership Plans</h2>
    <p>valai has been getting multiple queries from different countries for partners resellership plans. Due to immense
      queries we have planned to launch our partners resellership plans very soon.
    </p>
  </div>
</div>

<div class="partner-information-container">
  <div class="partner-information">
    <p>Schools are an essential part of every society whether it is rural or urban which makes this world full of
      schools. Targeting such type of big market from a single platform is an impossible job but we can increase our
      market share by co-working with the people and companies who are already in the field in different parts of the
      world.</p>

    <div class="reseller-plans">
      <h2 class="reseller-heading">With our partners – resellership plans one would have the following benefits.</h2>
    </div>

    <section class="reseller-plans-information">
      <span class="material-symbols-outlined">
        done
      </span>
      <p>Heavy discount on each purchase of license</p>
    </section>

    <section class="reseller-plans-information">
      <span class="material-symbols-outlined">
        done
      </span>
      <p>Right to participate in future module development.</p>
    </section>

    <section class="reseller-plans-information">
      <span class="material-symbols-outlined">
        done
      </span>
      <p>Availability of White Labeling</p>
    </section>

    <section class="reseller-plans-information">
      <span class="material-symbols-outlined">
        done
      </span>
      <p>Complete support, Training and Solutions by our tech Support team.</p>
    </section>

    <section class="reseller-plans-information">
      <span class="material-symbols-outlined">
        done
      </span>
      <p>Phone and Email Support</p>
    </section>

    <section class="reseller-plans-information">
      <span class="material-symbols-outlined">
        done
      </span>
      <p>New account creation for client from reseller panel.</p>
    </section>

    <section class="reseller-plans-information">
      <span class="material-symbols-outlined">
        done
      </span>
      <p>We have planned to launch different types of resellership plans for different types of needs.</p>
    </section>

    <div class="reseller-plans">
      <h2 class="reseller-heading">Resellership Plans Types</h2>
    </div>


    <section class="reseller-plans-information">
      <span class="material-symbols-outlined">
        done
      </span>
      <p>Basic</p>
    </section>

    <section class="reseller-plans-information">
      <span class="material-symbols-outlined">
        done
      </span>
      <p>White Labelled</p>
    </section>

    <section class="reseller-plans-information">
      <span class="material-symbols-outlined">
        done
      </span>
      <p>Enterprise Level</p>
    </section>

    <p>We would encourage the companies and Tech Persons related to this sector to fill out the given below form so that at the launch of resellership you could be intimated.</p>


    <h4 class="offer-text">There would be Special extra discount for the first 50 resellers.</h4>
  </div>

  <div class="partner-form">
    <div class="input-field">
      <p>Company Name</p>
      <section class="example-full-width">
        <input matInput [(ngModel)]="erpenquiry.name">
      </section>
    </div>

    <div class="input-field">
      <p>Type of Company</p>
      <section class="example-full-width">
        <select>
          <option label="-Please Select-"></option>
          <option value="IT">IT Company</option>
          <option value="NonIT">Non IT Company</option>
        </select>
      </section>

      <div class="input-field">
        <p>Email</p>
        <section class="example-full-width">
          <input type="email" matInput [(ngModel)]="erpenquiry.mailid">
        </section>
      </div>

      <div class="input-field">
        <p>Phone</p>
        <section class="example-full-width">
          <input type="number" matInput [(ngModel)]="erpenquiry.mobileno">
        </section>
      </div>

      <div class="input-field">
        <p>Country</p>
        <section class="example-full-width">
          <input matInput>
        </section>
      </div>

      <div class="input-field">
        <p>Your Message (required)</p>
        <section class="example-full-width">
          <textarea matInput [(ngModel)]="erpenquiry.message"></textarea>
        </section>
      </div>

      <button class="send-button"  (click)="submitSend()">Send</button>
    </div>
  </div>
</div>
