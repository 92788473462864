<div class="footer-container">
    <div class="footer-wrapper">
        <section>
            <nav>
                <ul>
                    <li> <a href="/blog"> Blog </a> </li>
                    <li> <a href="/privacy"> Privacy Policy </a> </li>
                    <li> <a href="/refundpolicy"> Cancellation & Refund Policy </a> </li>
                   
                    <li> <a href="/terms"> Terms Of Service </a> </li>
                </ul>
            </nav>
        </section>
        <section>
            <nav>
                <ul>
                    <li> <a href="https://login.valaischool.com" target="_blank" > Login </a> </li>
                    <li> <a href="/partners"> Partner </a> </li>
                    <li> <a href="/faq"> FAQ </a> </li>

                </ul>
            </nav>
        </section>
        <section>
            <img src="./../../assets/img/Logo.png" />
            <div>To manage all elements of student information system including students, courses, exams teachers and employees.</div>
        </section>
        <section>
            <label> CONTACT INFO </label>

            <section class="contact-info">

                <i class="fa fa-phone" aria-hidden="true"></i>
                <span> Phone </span>
                <a href="tel:9845926911" class="value"> +91 98459 26911 </a>
         

                <i class="fa fa-whatsapp" aria-hidden="true"></i>
                <span> WhatsApp </span>
                <a href="https://api.whatsapp.com/send?phone=919845926911" class="value"> +91 98459 26911 </a>
       
                <i class="fa fa-envelope" aria-hidden="true"></i>
                <span> Email: </span>
                <a href="mailto:sales@evalai.com" class="value"> sales@evalai.com </a>
            </section>

            <a href="https://www.facebook.com/ValaiSchool'" class="fa fa-facebook"></a>
            <a href="https://twitter.com/valaischool" class="fa fa-twitter"></a>
            <a href="https://www.linkedin.com/in/valaischool/" class="fa fa-linkedin"></a>
           
        </section>
    </div>
    <div class="center">
        © 2024 eValai Infotech Solutions. All Rights Reserved
    </div>
    
</div>
