import { HttpClient } from '@angular/common/http';
import { Component, OnInit ,ViewChild} from '@angular/core';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  //private subscriptions: PushSubscription[] = [];
  
  @ViewChild('closebutton') closebutton;

  
  constructor(private http:HttpClient) { }


  erpenquiry = { 'name': '', 'message': '',  'mobileno': '','mailid':'','status':'New Enquiry' ,'source':'Register'};

  ngOnInit(): void {
    
  }
 
  //public onSave() {
  //  this.closebutton.nativeElement.click();
  //}
  Inserterp() {

    debugger;
    let api = this.http.post('https://api.valaischool.com/api/Admin/inserterpenquiry',
      {
        
        'name': this.erpenquiry.name,
        'mobileno': this.erpenquiry.mobileno,
        'message': this.erpenquiry.message,
        'mailid': this.erpenquiry.mailid, 
        'status': this.erpenquiry.status, 
        'source': this.erpenquiry.source,    
        'mode': "INSERT"
      })
      .subscribe((data) => {

        if (data) {
          window.alert('Thank you for your interest in our service. Someone from our team will reach you soon');
          this.closebutton.nativeElement.click();
          //window.alert('Record Inserted');
          
        } else {
          window.alert('Unable to save the record!');
          this.closebutton.nativeElement.click();
          
        }

       
      },

      error => {
          console.log('Log the error here: ', error);
      });

  //  this.subscriptions.push(api);
  };

}
