<div class="terms">
    
    <h2>Terms Of Service</h2>


    <p>
        You are agreeing to be bound by the terms and conditions “Terms of Service” for using valai ERM “Service/Software” (Henceforth Service or Software). The service is provided by creativeON, the IT Company having its physical address at Flat No-10, First Floor, Glamour One Plaza, Township, Lahore, Pakistan.
    </p>

    <p>
        We reserve the right to make changes, additions, and deletions as we deem fit to these terms of service from time to time without any prior notice to the users of service. Any addition of new features, resources, and tools to augment the service performance shall be subject to these terms of service. If you carry on using the services after any changes we make in the terms of service shall constitute your consent to these changes. You are welcomed to review the most current version of these terms and conditions at http://www.valai.com/terms/
    </p>

    <p>
        For  <a href="/privacy">Privacy Policy </a>, you may visit: https://valai.com/privacy/
    </p>

    <p>
        Any violation of the terms of service outlined below could result in termination/suspension of your license or account.
    </p>

    <p>
        <strong>Primary Terms</strong>
    </p>

    <ul>
        <li>
            You should decline this agreement and stop using the service if you do not agree with these terms and conditions. The use of this service constitutes that you fully agree with the terms and conditions of the Terms of Service agreement.
        </li>
    </ul>

    <ul>
        <li>
            By using this service you agree that the Terms of Service agreement and relationship between the user and the service provider will be governed by the laws of the Islamic Republic of Pakistan without any conflict with law principles and international conventions. The exclusive jurisdiction for any dispute between the parties is Lahore High Court, Pakistan.
        </li>
    </ul>

    <ul>
        <li>
            You agree to provide your full legal name, physical address, valid email ID, and any other information we may require to complete the service provision process.
        </li>
    </ul>

    <ul>
        <li>
            Your login to the service may only be used by one person. However, you are allowed to create as many users as you desire to use the service.
        </li>
    </ul>

    <ul>
        <li>
            As a security obligation, you are responsible for the protection of your account and password to use the service. We, the service providers cannot and will not be held liable for any loss, damage or theft resulting from your failure to comply with the security obligation.
        </li>
    </ul>

    <ul>
        <li>
            It is your responsibility to notify any unauthorized access to your account and service to the service provider at support at valai dot com.
        </li>
    </ul>

    <ul>
        <li>
            You are responsible for all activities that occur on the service using your account details and valai.com will not be held liable for any such activity.
        </li>
    </ul>
    
    <ul>
        <li>
            Through these Terms of Service Agreement, you bind that you will not use the service for illegal or unauthorized purposes. You also bind that you will not violate the laws in your jurisdiction including Intellectual Property Rights and copyright laws.
        </li>
    </ul>

    <ul>
        <li>
            These terms of service bind you not to harass, threaten and abuse the service provider or any of its staff, affiliates, and subsidiaries in any way.
        </li>
    </ul>

    <p>
        <strong>Refunds, Upgrading, and Downgrading </strong>
    </p>

    <ul>
        <li>
            The service is provided and billed to you in advance as per the written terms agreed at the time of the deal between the parties; however there will be no refunds or credits, upgrade/downgrade refunds or any refund for unused months other than the refunds, credits agreed at the time of the deal.
        </li>
        <li>
            You will be informed about the upgrading of the service and will be charged accordingly.
        </li>
        <li>
            The fees for use of the service are exclusive of all taxes, and duties imposed by the tax authorities and you will be responsible for payment of all such taxes and duties.
        </li>
    </ul>
    <p>
        <strong>Cancellation and Termination</strong>
    </p>

    <ul>
        <li>
            In a case for the reasons best known to you, you want to cancel our service. You have to contact valai.com from your valid contact number or email for the cancellation process. You are welcome to contact support at valai dot com to know the cancellation procedure.
        </li>
        <li>
            The cancellation will result in immediate removal of your data from the service and you will not be able to recover this information whatsoever once your account is canceled.
        </li>
        <li>
            If you chose to cancel the service before the end of your current paid up period. Your service will be canceled and you will not be charged for the rest of the period.
        </li>
        <li>
            We reserve the right to terminate/suspend the service as our sole discretion in case of any unauthorized or illegal use is found. The termination could result in the deactivation of your account or access to your account. In such cases, all the data will be forfeited and relinquished by us. We reserve the right to refuse service to anyone for any reason at any time.
        </li>
    </ul>
    <p>
        <strong>Modifications terms</strong>
    </p>

    <ul>
        <li>
            We, the service providers reserve the right at any time and from time to time to modify or discontinue, temporarily or permanently, the Service (or any part thereof) with or without notice.
        </li>
        <li>
            The service provider shall not be liable to you or to any third party for any modification, suspension or discontinuance of the Service.
        </li>
        <li>
            You acknowledge and agree that the form and nature of the Service may change from time to time without prior notice to you due to the fact that we are constantly innovating and improving the Service.
        </li>
    </ul>
    
    <p>
        <strong>Content terms</strong>
    </p>
    
    <ul>
        <li>
            The content with the service is protected under copyright law. You are not allowed in any circumstances to copy, modify, lease, sell, distribute, derivate, decompile or otherwise attempt to extract the source code of the service without our prior permission under respective license.
        </li>
        <li>
            You agree to undertake not to create, transmit, display or make otherwise available any content that is unlawful, harmful, threatening, abusive, harassing, tortuous, defamatory, vulgar, obscene, invasive of another’s privacy, or hateful (incl. viruses, worms, and any other destructive codes) through the use of service.
        </li>
    </ul>

    <p>
        <strong>General Conditions</strong>
    </p>

    <ul>
        <li>
            Your use of the service is at your sole risk. We provide service as is and as available basis.
        </li>
        <li>
            We as service providers do not represent or warrant that;
        </li>
    </ul>        
    
    <ul>
        <li>
            The service will meet all your requirements
        </li>
        <li>
            The use of service will be uninterrupted, timely, secure and free from any errors
        </li>
        <li>
            The information obtained by you through the use of service will be accurate, correct, reliable and up to date
        </li>
        <li>
            The service does not contain any defects in operation and functionality.
        </li>
    </ul>
    <ul>
        <li>
            Technical support is available through email.
        </li>
        <li>
            No warranties for satisfactory quality, fitness for purpose or conformance with the description apply to the service except to the extent expressly stipulated in the agreement.
        </li>
        <li>
            We are not liable for any direct, indirect or consequential damage that may include but not limited to loss of profits, loss of data, loss of goodwill or reputation that may incur to you in relation to ht service, including;
        </li>
    </ul>
    <ul>
        <li>
            Damage that may result from any changes made in the service by the service provider;
        </li>
        <li>
            Damage resulting from any interruption (permanent or temporary) in the provision of the service;
        </li>
        <li>
            Damage that may result from deletion, corruption or failure to store your data using the service;
        </li>
        <li>
            Damage that may occur due to your failure to provide us with accurate account information;
        </li>
        <li>
            Damage resulting from your failure in the protection of your password and other confidential details;
        </li>
    </ul>
    <ul>
        <li>
            You must not try to modify or create copies of the service or make such change that associates our service with you or any of your affiliates.
        </li>
        <li>
            You understand that we use third party vendors and hosting partners to provide you with software, storage and any other related technology that you may require to run the service.
        </li>
        <li>
            You agree not to reproduce any duplicates or exploit any of the service portions without explicit permission from us as the service provider.
        </li>
        <li>
            You must indemnify us as the service provider, our officers, directors, employees, agents, licensors, and suppliers for any claims, liabilities, losses, damage, and costs including but not limited to the attorney fee that may result from breach of this agreement or from other unlawful activities through the use of service.
        </li>
        <li>
            We may but have no obligation to remove, suspend or terminate accounts in our sole discretion that we find are unlawful and producing content that is offensive, threatening, libelous, defamatory, pornographic or otherwise objectionable or violates any party’s intellectual property rights or these terms and conditions.
        </li>
        <li>
            You agree that we may administer you notices, including about the changes in the service, by email, regular mail or through any courier service.
        </li>
        <li>
            You understand that the processing and transmission of the service could be encrypted or unencrypted and could involve several networks and can change to the conformity of the technical requirements of these networks and devices.
        </li>
        <li>
            You agree not to use the service for posting or transmitting unsolicited emails, SMS or spam messages.
        </li>
        <li>
            You agree not to transmit any viruses or worms or any such codes of destructive nature through the use of this service.
        </li>
        <li>
            Nothing in the Agreement shall exclude or limit the Service Provider’s liability for damage which may not be lawfully excluded or limited by the laws of Islamic Republic of Pakistan
        </li>
        <li>
            The failure of the service provider to exercise or enforce any right or provision of the Terms of Service shall not constitute a waiver of such right or provision. The Terms of Service constitutes the entire agreement between you and service provider and govern your use of the Service, superseding any prior agreements between you and service provider (including, but not limited to, any prior versions of the Terms of Service).
        </li>
        <li>
            The Agreement is governed by the laws of Islamic Republic of Pakistan.
        </li>
        <li>
            In case the Agreement has been translated into any other language than English, the contradictions between the English version and the translation, the English version shall prevail over the translation.
        </li>
    </ul>
    <p>
        This document was last updated on April 24, 2022.
    </p>
</div>