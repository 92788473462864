<div class="home-container">
    <div class="sms">
        <div class="sms-wrapper">
            <div class="left-pane">
                <h2> School Management Software - Mobile and Web</h2>
                <h6> Cloud based School ERP to manage your school in your mobile, starting from admissions to Fee Management and attendance to Report cards.</h6>
                <div class="btn-container">
                    <button class="primary-btn"> 
                        <a href="/signup">Sign Up</a></button>
               
                </div>
            </div>
            <div class="right-pane">
                <img src="../../assets/img/sms.png" alt="">
            </div>
        </div>
    </div>

    <div class="setup">
        <iframe width="560" height="315" src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FValaiSchool%2Fvideos%2F490899592399513%2F&show_text=false&width=560&t=0" frameborder="0" allowfullscreen></iframe>
        <div class="setup-details">
            <div class="title">
                Made in India!.. Made for Indian Schools! 
            </div>
            <hr />
            <div class="desc">
                Digitalize your daily tasks & operations easily like Pre-admission , Fee , LMS , ID Cards, Bio-metric device integrtion, Payroll, Leave Management etc..
            </div>
        </div>
    </div>

    <div class="admin-sw">
        <div class="admin-sw-container">

            <div class="admin-sw-details">
                <div class="title">
                    CBSE CCE Report Card and State Board Report Card Automation Software
                </div>
                <div class="desc">
                    Pre-defined CCE Report Card templates as well as custom made report card formats are available based on your package. For custom made versions, we match your report card format by 100%. All teachers can use this report card module to enter the marks. Parents can view / download the report card from their mobile.
                </div>
                
                <button class="primary-btn"> 
                    <a href="/signup">Signup!</a>
                </button>
            </div>

            <img src="../../assets/img/macbook.png" alt="">
        </div>
    </div>
    
    <div class="why-valai">
        <div class="title"> Android App with your school name in playstore ! <span class="valai-color"></span> </div>

        <main>
            <div class="left-pane">
                <article>
                    <div> Easy to Use Mobile App </div>
                    <i class="fa fa-mobile orange" aria-hidden="true"></i>
                </article>
            
                <article>
                    <div> Student Reports </div>
                    <i class="fa fa-files-o pink" aria-hidden="true"></i>
                </article>
            
                <article>
                    <div> Report Cards and Marks </div>
                    <i class="fa fa-list turquoise" aria-hidden="true"></i>
                </article>
            
                <article>
                    <div> Online Fee Payment </div>
                    <i class="fa fa-dollar cherry" aria-hidden="true"></i>
                </article>

                <article>
                    <div> Attendance Entry and Reports </div>
                    <i class="fa fa-search purple" aria-hidden="true"></i>
                </article>
            </div>
            
            <img src="../../assets/img/mobile.png" alt="">

            <div class="right-pane">
                <article>
                    <i class="fa fa-weixin purple" aria-hidden="true"></i>
                    <div> Communication </div>
                </article>
            
                <article>
                    <i class="fa fa-headphones orange" aria-hidden="true"></i>
                    <div> Unlimited Notifications </div>
                </article>
            
                <article>
                    <i class="fa fa-lock pink" aria-hidden="true"></i>
                    <div> Offline Contents </div>
                </article>
            
                <article>
                    <i class="fa fa-share-alt cyan" aria-hidden="true"></i>
                    <div> For Management & Staffs & Parents </div>
                </article>

                <article>
                    <i class="fa fa-connectdevelop turquoise" aria-hidden="true"></i>
                    <div> Easy to send Circulars and Home Work </div>
                </article>
            </div>
        </main>

    </div>

    <div class="more-sms">
        <div class="more-sms-wraper">
            <h2 for=""> Explore <span class="valai-color"> More </span> School Management System Modules </h2>

            <div class="features">
                <div class="row">
                    <div class="box">
                        <img src="../../assets/img/Admission.png" />
                        <div class="title"> Admission </div>
                        <div class="desc"> Integrated with School Website </div>
                    </div>

                    <div class="box">
                        <img src="../../assets/img/Attendance.png" />
                        <div class="title"> Attendance </div>
                        <div class="desc"> Integrated with Bio-Metric Devices </div>
                    </div>

                    <div class="box">
                        <img src="../../assets/img/Examination.png" />
                        <div class="title"> Fee Module </div>
                        <div class="desc"> Integrated with Payment Gateway </div>
                    </div>

                    <div class="box">
                        <img src="../../assets/img/LMS.png" />
                        <div class="title"> Online Class </div>
                        <div class="desc"> Integrated with Zoom/Teams/Meet </div>
                    </div>

                    <div class="box">
                        <img src="../../assets/img/Finance.png" />
                        <div class="title"> SMS </div>
                        <div class="desc"> Integrated with SMS Gateways </div>
                    </div>
                </div>
                <div class="row">
                    <div class="box">
                        <img src="../../assets/img/SMS-1.png" />
                        <div class="title"> Student </div>
                        <div class="desc"> Management System </div>
                    </div>

                    <div class="box">
                        <img src="../../assets/img/TMS.png  " />
                        <div class="title"> Timetable </div>
                        <div class="desc"> Management System </div>
                    </div>

                    <div class="box">
                        <img src="../../assets/img/Admin.png" />
                        <div class="title"> Administration </div>
                        <div class="desc"> Management System </div>
                    </div>

                    <div class="box">
                        <img src="../../assets/img/Transport.png" />
                        <div class="title"> Transport  </div>
                        <div class="desc"> Management System </div>
                    </div>

                    <div class="box">
                        <img src="../../assets/img/Library.png" />
                        <div class="title"> Library </div>
                        <div class="desc"> Management System </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="teacher">
        <div class="teacher-container">
            <div class="teacher-details">
                <div class="title">
                    Replace those existing software to the most advanced, centralized and cost effective education ERP suite.
                    Small School ?. Big Schools with 1000s of students?. All can afford. 
                </div>
                <button class="primary-btn"> <a href="/signup">Sign Up</a> </button>
        
            </div>

            <img src="../../assets/img/teacher.png" alt="">
        </div>

        <div class="web-container">
            <img src="../../assets/img/software.png" alt="">

            <div class="web-details">
                <div class="title">
                    Road Map to implement our School ERP Modules .
                </div>
                <div class="desc">
                    Sign up <br/> 
                    Configure Master Data like Academic year, Class, Sections etc. <br/> 
                    Student and Staff Data upload ( Bulk OR One by one ) <br/>
                    Trainng to Admin Staffs <br/>
                    Fee Module Configuration and Fee Processing <br/>
                    Training to Teaching Staffs <br/>
                    Training videos to Parents <br/>
                    COnfigure Time Table <br/>
                    Upload E-Learning contents <br/>
                    Configure ID Card <br/>
                    Configure Report Card <br/>
                    Configure Transfer Certificate <br/>
                    Configure Bonafide Certificate <br/>
                    Configure Study Certificate <br/>
                    Transport Module Configuration <br/>
                    All other modules' Configuration <br/>
                    Customization as per School's needs <br/>
                </div>
            </div>
        </div>
    </div>

    <div class="features">
        <div class="features-container">
                <div class="box">
                    <img src="../../assets/img/f1.png" alt="">
                    <div class="title"> Configurable </div>
                    <div class="desc"> Easy to configure to cater the specific needs of your institute. </div>
                </div>

                <div class="box">
                    <img src="../../assets/img/f2.png" alt="">
                    <div class="title"> Mobile and tablet versions </div>
                    <div class="desc"> Mobile versions use offline data. This will help students to view Videos/Images and other study materials without internet connections </div>
                </div>

                <div class="box">
                    <img src="../../assets/img/f3.png" alt="">
                    <div class="title"> Hosted on Cloud </div>
                    <div class="desc"> As per CBSE norms, we hosted our ERP in India Based Data Center. We hosted with <a href="https://www.cloud4c.com">Cloud4C </a>  who is India's largest Data Center provider </div>
                </div>

                <div class="box">
                    <img src="../../assets/img/f4.png" alt="">
                    <div class="title"> Secure and Reliable </div>
                    <div class="desc"> 100s of schools trust Valai School. It is built on security guidelines for modern web applications. Our cloud takes daily backup. Also schools can download core data anytime  </div>
                </div>

                <div class="box">
                    <img src="../../assets/img/f5.png" alt="">
                    <div class="title"> Unmatched Support </div>
                    <div class="desc">Our team supports you in Hindi/English/Kannada/Telugu/Tamil languages. We help you with User Manuals, Training Videos and live online training. </div>
                </div>

                <div class="box">
                    <img src="../../assets/img/f6.png" alt="">
                    <div class="title"> Wide Range of Features </div>
                    <div class="desc"> Valai offers wide range of features than any other private school management system software in the market. It’s practical and widely used and still enhancing. You will be auto-updated as new features are added. </div>
                </div>
        
                <div class="box">
                    <img src="../../assets/img/f7.png" alt="">
                    <div class="title"> Flexible Pricing</div>
                    <div class="desc"> valai is available as  School Software and we also have flexible pricing plans for institutions of different sizes. You can start with  plan and can upgrade to paid when required. </div>
                </div>

                <div class="box">
                    <img src="../../assets/img/f8.png" alt="">
                    <div class="title"> Multiple Language Support </div>
                    <div class="desc"> valai is a multi-lingual school software, it’s easily translatable into your own language. We have made even a web interface that allows you to easily translate or rephrase or change any label/text to suit your school, college needs.</div>
                </div>

                <div class="box">
                    <img src="../../assets/img/f8.png" alt="">
                    <div class="title"> Multiple Language Support </div>
                    <div class="desc"> valai is a multi-lingual school software, it’s easily translatable into your own language. We have made even a web interface that allows you to easily translate or rephrase or change any label/text to suit your school, college needs.</div>
                </div>
        </div> 
    </div>

    <div class="erp">
        <div class="erp-container">
            <div class="erp-details">
                <div class="title">
                    A Complete School ERP Software
                </div>
                <div class="desc">
                    School Administration Software offers one common school database systems and configurable user profiles for everyone in your school. It’s allowing administration, teachers, students and parents to share the information with each other.
                </div>
                
            </div>

            <img src="../../assets/img/macbook.png" alt="">
        </div>
    </div>
</div>