<div class="contact-container">
  <div class="contact-wrapper">
    <h2 style="color: black;">Sales Inquiries</h2>
    <p>To know more about our products & services, please fill below form
    </p>
  </div>
</div>


<div class="sales-information-container">
  <div class="sales-information">
    <div class="sales-header">
      <h3 style="color: black;">Our Sales Team is available Monday-Friday, 9:30 AM to 6 PM ( IST )</h3>
    </div>

    <div class="partner-form">
      <div class="input-field">
        <p>Name </p>
        <section class="example-full-width">
          <input matInput [(ngModel)]="erpenquiry.name">
        </section>
      </div>

      <div class="input-field">
        <p>Mobile</p>
        <section class="example-full-width">
          <input type="number" matInput [(ngModel)]="erpenquiry.mobileno">
        </section>
      </div>

      <div class="input-field">
        <p>Email</p>
        <section class="example-full-width">
          <input type="email" matInput [(ngModel)]="erpenquiry.mailid">
        </section>
      </div>

      <div class="input-field">
        <p>How did you hear about us?</p>
        <section class="example-full-width">
          <select>
            <option label="-Please Select-"></option>
            <option>Google</option>
            <option>Advertisement</option>
            <option>Facebook</option>
            <option>Referred by a friend</option>
            <option>Other</option>
          </select>
        </section>
      </div>

      <div class="input-field">
        <p>Your Message</p>
        <section class="example-full-width">
          <textarea matInput [(ngModel)]="erpenquiry.message"></textarea>
        </section>
      </div>

      <button class="send-button" (click)="submitSend()">Send</button>
    </div>
  </div>

  <div class="contact-information">
    <mat-card>

      <img src="./../../assets/img/Logo.png">

      <div class="info-container">
      <!--  
         <section class="info">
          <h3>Cell:</h3>
          <p>+91 98459 26911</p>
        </section>
      -->

        <section class="info">
          <h3>Whatsapp:</h3>
          <p>+91 98459 26911</p>
        </section>

      </div>

      <div class="professional-info">
        <div class="professional-detail">
          <mat-icon class="material-symbols-outlined">
            mail
          </mat-icon>
          <section class="info-detail">
            <p>sales@evalai.com</p>
          </section>
          </div>

          <div class="professional-detail">
          <mat-icon class="material-symbols-outlined">
            call
          </mat-icon>
          <section class="info-detail">
            <p class="info-detail-toll">+91 98459 26911</p>
          </section>
        </div>

        <div class="professional-detail">
          <mat-icon class="material-symbols-outlined">
            pin_drop
          </mat-icon>
          <section class="info-detail">
            <p>India</p>
          </section>
        </div>

        <!-- <div class="professional-detail">
          <mat-icon class="material-symbols-outlined">
            support
          </mat-icon>
          <section class="info-detail">
            <p>For support requests, please visit our support center.</p>
          </section>
        </div>

        <button class="send-button support">Support Center</button> -->
      </div>
    </mat-card>
  </div>
</div>

<!--<div class="map">
  <img src="../../assets/img/map.png">
</div>
--> 

