<div class="signup">
    <div class="signup-container">
        <div class="signup-wrapper">
        <h2> Your Key to Success Free School Software Registration </h2>
        <p>Do you really need it? This will help you decide! Don’t just sit there! Start free school software registration </p>
        </div>
    </div>

    <div class="signup-details">
        <div class="left-pane">
            <h2>
                Free App: valai School Management System
            </h2>

            <div>
                valai helps management manage all aspects of the school including students, examinations, and employees. Use valai Totally Free.
            </div>

            <section class="plan-info">
                <span class="material-symbols-outlined">
                    done
                </span>
                <span class="detail">
                    Get free School Management Software App
                </span>
            </section>

            <section class="plan-info">
                <span class="material-symbols-outlined">
                    done
                </span>
                <span class="detail">
                    Options to include demo data
                </span>
            </section>

            <section class="plan-info">
                <span class="material-symbols-outlined">
                    done
                </span>
                <span class="detail">
                    Personalized School App in the Cloud
                </span>
            </section>

            <section class="plan-info">
                <span class="material-symbols-outlined">
                    done
                </span>
                <span class="detail">
                    Takes 5 Minute to setup.
                </span>
            </section>

            <button class="primary-btn" data-toggle="modal" data-target="#myModal" data-whatever="@mdo"> 
                <!-- <a href="/signup">Sign Up</a> -->
                Register
            </button>
            <!--<button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo">Open modal for @mdo

            </button>-->
        </div>
        <div class="right-pane">
            <iframe src="https://www.youtube.com/embed/964uFZnjqwg" frameborder="0" allowfullscreen></iframe>
        </div>
    </div>
</div>


<div class="container">
  
  <!-- Trigger the modal with a button -->

  <!-- Modal -->
  <div class="modal fade" id="myModal" role="dialog">
    <div class="modal-dialog">
    
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" #closebutton class="close" data-dismiss="modal">&times;</button>
        </div>

        <div class="modal-body">
          <form>
            <div class="form-group">
              <label for="name" class="col-form-label">Name <span style="color: red;">*</span>:</label>
             
              <input type="text" class="form-control" [(ngModel)]="erpenquiry.name"
               id="name" name="name" >
            </div>
            <div class="form-group">
                <label for="mobileno" class="col-form-label">Mobile No <span style="color: red;">*</span>:</label>
                <input type="number" class="form-control" [(ngModel)]="erpenquiry.mobileno" id="mobileno" name="mobileno">
              </div>
              <div class="form-group">
                <label for="mailid" class="col-form-label">Mail Id:</label>
                <input type="email" class="form-control" [(ngModel)]="erpenquiry.mailid" id="mailid" name="mailid">
              </div>
            <div class="form-group">
              <label for="message" class="col-form-label">Message:</label>
              <textarea class="form-control" [(ngModel)]="erpenquiry.message" id="message" name="message"></textarea>
            </div>
          </form>
        </div>

        
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" id="closeModal"  (click)="Inserterp()">Save</button>
         </div> 
      </div>
    </div>
  </div>
  
</div>




<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Sign up</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-group">
              <label for="name" class="col-form-label">Name:</label>
              <input type="text" class="form-control" [(ngModel)]="erpenquiry.name"
               id="name" name="name" >
            </div>
            <div class="form-group">
                <label for="mobileno" class="col-form-label">Mobile No.:</label>
                <input type="text" class="form-control" [(ngModel)]="erpenquiry.mobileno" id="mobileno" name="mobileno">
              </div>
              <div class="form-group">
                <label for="mailid" class="col-form-label">Mail Id:</label>
                <input type="text" class="form-control" [(ngModel)]="erpenquiry.mailid" id="mailid" name="mailid">
              </div>
            <div class="form-group">
              <label for="message" class="col-form-label">Message:</label>
              <textarea class="form-control" [(ngModel)]="erpenquiry.message" id="message" name="message"></textarea>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" (click)="Inserterp()">Submit</button>
        </div>
      </div>
    </div>
  </div>