<div class="faq">
    <div class="faq-container">
        <div class="left-pane">
            <h3>General</h3>
            <p>
                If you have a question and not answered found here, please contact our <a href="https://valai.com/support/">support team</a>.
            </p>
            <h3>
                1. Can I Install valai on my own hosting?
            </h3>
            <p>
                Yes, valai could be installed on any Linux based hosting. You would need to <a href="/pricing">buy our One Time Branded</a> Subscription and our technical team would assist you to install the app.
            </p>
            <h3>
                2. Do I need to pay any charges for Renewal?
            </h3>
            <p>
                Yes and also no, In case of monthly subscription user would not pay anything for Support, Hosting, or renewal. But in case of One Time Branded Subscription user would have to pay $100/yr after one of the purchase. The Renewal charges would cover, Support, New modules updates, Cloud Hosting and license renewal.
            </p>
            <h3>
                3. How can I pay for valai?
            </h3>
            <p>
                You can pay using a credit card or PayPal for any of the subscriptions for valai. You would need to <a href="/pricing">place your order</a> on our site and invoice would be sent to you via email, which would be used to pay the pending amount.
            </p>
            <h3>
                4. Does valai Support SMS Integration and what do I need for it?
            </h3>
            <p>
                Yes, valai <a href="/">School Management System</a> includes the module of SMS. You would need to buy an SMS package from your local vendor. We would need your SMS account to get the API Code and our tech staff would configure your local SMS account with the valai School Management System.
            </p>
            <h3>
                5. How to run valai on Local Server Environment?
            </h3>
            <p>
                Although the valai School Management System is a Web Application it could also be installed on Local server environment but the charges for offline version are entirely different. The user would need a Linux based server environment and would need to have Apache Server installed on the machine and would need MySQL Support.
            </p>
        </div>

        <div class="right-pane">
            <h4>Categories</h4>
            <ul>
                <li>
                    <a href="/" >Getting Started </a>
                </li>
                <li>
                    <a href="/" >Using Valai</a>
                </li>
            </ul>

            <h4>Sales Questions</h4>
            <div>Talk to a team member today. </div>
            <button>
                <i class="fa fa-envelope" aria-hidden="true"></i>
                Send a message
            </button>
        </div>
    </div>
</div>