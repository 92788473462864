<div class="feature-container">
  <div class="feature-header-container">
    <h2>Multiple School Access & Other Features</h2>

    <div class="feature-information">

      <section class="feature-points">
        <p class="feature-info-text">
         Users of different schools can access same application and switch over the roles inside the app.
         For example : Staff A may work in School 'X' and her 1st child may study in school 'Y' and 2nd child at school 'Z'.
         She can access all 3 schools' information in her mobile app using the same login. No need to install separate app OR seperate login </p>
        <p class="feature-info-text">
          Fee Management of ValaiSchool is highly flexible, easy to configure by any accountant OR admin staff of the school. 
          Online payment gateway from <a href="www.billdesk.com"  target="_blank">BillDesk</a> is part of the product. Admins or teachers can create
          online classes from within the app and Link for meeting are both updated into students and teachers panels.
        </p>

        <p class="feature-point-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          Different Fee Settings for New Vs Existing Students
        </p>

        <p class="feature-point-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          Separate Transport Fee Configuration
        </p>

        <p class="feature-point-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          Separate Hostel Fee Configuration
        </p>

        <p class="feature-point-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          Separate Food Fee Configuration
        </p>

        <p class="feature-point-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          Paid/Unpaid & Account Receivable Reports
        </p>
      </section>

      <div class="feature-video">
        <iframe src="https://www.youtube.com/embed/964uFZnjqwg" allowfullscreen></iframe>
      </div>
    </div>
  </div>
<!-- 
  <div class="student-management-container">
    <h2 style="color: black;">Report card Automation</h2>
    <p>Automation of CCE Report card generation for <a href="https://cbseacademic.nic.in" target="_blank" > CBSE board </a> and state board syllabus is core part of our ERP.
      Respective class teachers will have access to all subjects and Subject Teachers will have access ONLY to those subjects assigned to them.
      You can publish report card once marks for all students entered into the system. 
    </p>
  </div> -->

  <div class="why-school-management-container">
    <section class="header-container">
      <h2 class="header" style="color: aliceblue;">Core Features of Valai School Management Software</h2>
      <p class="header-subtext" style="color: aliceblue;">All these features are configurable and customizable </p>
    </section>

    <div class="feature-card-container">

      <section class="feature-card" style="background-color: aliceblue">
        <mat-icon class="material-icons-outlined card-icon">contact_mail</mat-icon>
        <p class="card-header">Student Information</p>

        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          Student Details
        </p>

        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          ID Card
        </p>

        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          Exam Hall Tickets
        </p>

        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          TC and other Certificates 
        </p>

        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          Special Child, RTE, Health Status
        </p>

      </section>

      <section class="feature-card">
        <mat-icon class="material-icons-outlined card-icon">contact_mail</mat-icon>
        <p class="card-header">Adminsitrators</p>

        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          School Performance Dashboard
        </p>

        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          HR & Payroll
        </p>

        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          Student / Staff Attendance
        </p>

        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          Import/Export School Data
        </p>

        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          Live Class / LMS Contents
        </p>

      </section>

      <section class="feature-card">
        <mat-icon class="material-icons-outlined card-icon">contact_mail</mat-icon>
        <p class="card-header">Accounting</p>

        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          Fee Management
        </p>

        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          Transport Fee Management
        </p>

        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          HOstel & Food Fee Management
        </p>

        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          Paid/Unpaid/Account Receivable Reports
        </p>


        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          Daily / Monthly / Yearly Reports
        </p>
      </section>

      <section class="feature-card">
        <mat-icon class="material-icons-outlined card-icon">contact_mail</mat-icon>
        <p class="card-header">Parent App</p>

        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          Study Materials
        </p>

        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          Circular & Assignments
        </p>

        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          Fee Payment and Reports
        </p>

        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          Live Class with Zoom/Teams/Google Meet 
        </p>

        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          Preconfigured Zoom Accounts
        </p>

      </section>

      <section class="feature-card">
        <mat-icon class="material-icons-outlined card-icon">contact_mail</mat-icon>
        <p class="card-header">Staffs</p>

        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          Class Teacher / Subject Teacher Assignments
        </p>

        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          Time Table 
        </p>

        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          Payroll and Leave Management
        </p>

        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          Mark Entry and Report Card 
        </p>

        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          Student Attendance
        </p>

      </section>

      <section class="feature-card">
        <mat-icon class="material-icons-outlined card-icon">contact_mail</mat-icon>
        <p class="card-header" style="color: black;">Report Card Automation</p>

        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          CBSE CCE Report Card
        </p>

        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          State Board and ICSE Report Cards
        </p>

        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          Report Card Publish Option
        </p>

        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          Marks & Report card in Mobile App 
        </p>

        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          Customized Report Card
        </p>

      </section>

      <section class="feature-card">
        <mat-icon class="material-icons-outlined card-icon">contact_mail</mat-icon>
        <p class="card-header">Android App</p>

        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          Single App for all schools
        </p>

        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          Single App for Admin / Staffs / Parents
        </p>

        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          Offline contents of LMS
        </p>

        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          Circular & Assignement
        </p>

        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          Online Fee Payment
        </p>

      </section>

      <section class="feature-card">
        <mat-icon class="material-icons-outlined card-icon">contact_mail</mat-icon>
        <p class="card-header">External Interfaces</p>

        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          Bio-metric devices for Attendance
        </p>

        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          Payment Gateway
        </p>

        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          SMS Gateway
        </p>

        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          Website integration API for Admission
        </p>

        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          GPS Devices
        </p>

      </section>

      <section class="feature-card">
        <mat-icon class="material-icons-outlined card-icon">contact_mail</mat-icon>
        <p class="card-header">Learning Management</p>

        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          Your own Learning Materials
        </p>

        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          No limitation on number of files
        </p>

        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          Online exam
        </p>

        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          Live class integration
        </p>

        <p class="card-text">
          <mat-icon class="material-icons-outlined">check</mat-icon>
          Offline Contents for Students
        </p>

      </section>

    </div>
  </div>
  <ng-container>

    <div class="sis-features-container">

      <section class="sis-features-header-container">

        <h2 class="sis-features-header" style="color: black;">Fee Management System</h2>
<!-- 
        <h2 class="sis-features-sub-header">Take care of basic financial needs to embed options</h2>

        <p class="sis-features-header-text">Incoming and outgoing of funds will remain a crucial issue for every
          educational institute
        </p> -->

      </section>

    </div>

    <div class="sis-feature-description-container">

      <section class="sis-feature-desc-block">
        <h2>Fee Setting</h2>

        <img src="../../assets/img/screenshots/feeSettings.PNG" alt="">

        <p>Duration of a fee can be set as  Monthly , Quarterly , yearly and one time  OR adhoc Fee duration for quick settings.Fee Quota helps the school to group the Fee under one structure. Fee receipts can be configured as A4 , Duel Print outs OR A5 etc.</p>

      </section>
      <section class="sis-feature-desc-block">

        <h2>Fee structure</h2>

        <img src="../../assets/img/screenshots/feeStructure.PNG" alt="">

        <p>Different fee structures for newly joined students and existing students can be configured in our Fee Module.Also, instalments can be configured. And, fine amount can be configured for delayed payments.
        </p>

      </section>

      
      <section class="sis-feature-desc-block">

        <h2>Daywise Fee Report</h2>

        <img src="../../assets/img/screenshots/feeReport.PNG" alt="">

        <p>
      There are multiple fee reports available. Daywise fee report is one of them. User can see the total amount of fees with different structure by selecting the date.  </p>

      </section>

      <section class="sis-feature-desc-block">
        <h2>Paid-Unpaid Fee Report</h2>

        <img src="../../assets/img/screenshots/feeReport2.PNG" alt="">

        <p>
          Paid-Unpaid fee report used to differentiate the paid fee and unpaid fee based on the classes.
        </p>

      </section>

 
    </div>
  </ng-container>

  <ng-container>

    <div class="sis-features-container">

      <section class="sis-features-header-container">

        <h2 class="sis-features-header" style="color: black;">Communication </h2>

        <h2 class="sis-features-sub-header">Multiple channels to share information between Management, Teachers,
          Parents, and Students</h2>

        <p class="sis-features-header-text">Communication is the backbone of every organization. valai school
          management system <a href="">School Management System</a>
          has multiple features to provide assistance in communicating with Parents and Students.
        </p>

      </section>

    </div>

    <div class="sis-feature-description-container">

      <section class="sis-feature-desc-block">

        <h2>Circular Module</h2>

        <img src="../../assets/img/screenshots/circular1.PNG" alt="">

        <p>
          Circular is the key module to communicate the parents & staffs , circular will be sent to parents mobile app as a notification. It can be a single student or all student.</p>

      </section>

      <section class="sis-feature-desc-block">

        <h2>Assignment Module</h2>

        <img src="../../assets/img/screenshots/assignment.PNG" alt="">

        <p>
          Assignment module used to send assignment to students by class.
        </p>

      </section>



    </div>
  </ng-container>

  <ng-container>

    <div class="sis-features-container">

      <section class="sis-features-header-container">

        <h2 class="sis-features-header" style="color: black;">Mark Entry & Report Cards</h2>

        <p class="sis-features-header-text">
          Mark Entry & Report card are the key feature of the ERP. Mark entry screen has a provision to add max marks to each subjects and add marks to students, mark additional subject marks and mark attendance etc.,
          Once mark entry is done , we can generate systematic report card for single student or all students.
        </p>

      </section>

    </div>

    <div class="sis-feature-description-container">

      <section class="sis-feature-desc-block">

        <h2>Mark entry</h2>

        <img src="../../assets/img/screenshots/markenrty.PNG" alt="">

        <p>Mark Entry & Report card are the key feature of the ERP. Mark entry screen has a provision to add max marks to each subjects and add marks to students, mark additional subject marks and mark attendance etc.,
          
        </p>

      </section>


      <section class="sis-feature-desc-block">

        <h2>Report Card</h2>

        <img src="../../assets/img/screenshots/reportcard1.PNG" alt="">

        <p>  Once mark entry is done , we can generate systematic report card for single student or all students.
        
        </p>

      </section>

    </div>
  </ng-container>

  <ng-container>

    <div class="sis-features-container">

      <section class="sis-features-header-container">

        <h2 class="sis-features-header" style="color: black;">Academic Details</h2>
        <p class="sis-features-header-text">
          Each academic year data is stored as a separate layer in our ERP. At any point of time, you can rollback to view old data.  </section>

    </div>

    <div class="sis-feature-description-container">

      <section class="sis-feature-desc-block">

        <h2>Academic Year</h2>

        <img src="../../assets/img/screenshots/academicyear.PNG" alt="">

        <p>
          Users can add the academic years,  the period of time each year when people attend school, the student will be transferring to the next academic year.
        </p>

      </section>

      <section class="sis-feature-desc-block">

        <h2>Academic Subjects</h2>

        <img src="../../assets/img/screenshots/academicsubjects.PNG" alt="">

        <p>
          Users can add the subjects,  the period of time each academic year, the student will be taking those those subjects.
        </p>
      </section>

      <section class="sis-feature-desc-block">

        <h2>Academic class</h2>

        <img src="../../assets/img/screenshots/class.PNG" alt="">

        <p>
          Users can add the classes,  the period of time each academic year, the student will be assigned to defined classes.
        </p>
      </section>

      <section class="sis-feature-desc-block">

        <h2>Academic Branch & Section</h2>

        <img src="../../assets/img/screenshots/section.PNG" alt="">

        <p>
          Users can add the Branch & Section,  the period of time each academic year, the student will be assigned to defined branch & section under classes.
        </p>
      </section>

    </div>
  </ng-container>

  <ng-container>

    <div class="sis-features-container">

      <section class="sis-features-header-container">

        <h2 class="sis-features-header" style="color: black;">Student Management</h2>


        <p class="sis-features-header-text">Our ERP integrates students, parents, teachers and the administration. Our student management system captures all necessary details of students like Physically disabled / Single Parent / BMI / etc. 
          It has a predefined set of reports like list of students of different income category / caste / Food opted students etc.  </p>

      </section>

    </div>

    <div class="sis-feature-description-container">

      <section class="sis-feature-desc-block">

        <h2>Student Details</h2>

        <img src="../../assets/img/screenshots/studentDetails1.PNG" alt="">

        <p>
          Each academic year data stored as separate layor in our ERP. At any point of time, you can rollback to view old data. User can enter a different type of student information like; basic
          info, guardian info, photo upload, emergency contact details etc.</p>

      </section>

      <section class="sis-feature-desc-block">

        
        <h2>Pre-Admission</h2>

        <img src="../../assets/img/screenshots/enquiry.PNG" alt="">

        <p>
          Pre-admission module can be integrated with the school's website using APIs of our ERP.
          Also, the front desk team can enter all inquiries from any source like walk-in, phone, email etc.</p>

      </section>

      <section class="sis-feature-desc-block">

        <h2>Certificates</h2>

        <img src="../../assets/img/screenshots/tc.PNG" alt="">

        <p>
          User can create student certificates like Transfer certificates, Study certificates, Bonafide cerificates, Conduct certificates and E certificates.</p>

      </section>

      <section class="sis-feature-desc-block">

        <h2>Time Table</h2>

        <img src="../../assets/img/screenshots/timetable.PNG" alt="">

        <p>
      User can configure their own Time Table and assign to students. </p>

      </section>

    </div>
  </ng-container>

  <ng-container>

    <div class="sis-features-container">

      <section class="sis-features-header-container">

        <h2 class="sis-features-header" style="color: black;">Faculty Management</h2>

        <h2 class="sis-features-sub-header">Store and maintain faculty-related information on the secure database by implementing cloud-based faculty management system software designed by Valaischool</h2>

      </section>

    </div>

    <div class="sis-feature-description-container">

      <section class="sis-feature-desc-block">

        <h2>Faculty Details</h2>

        <img src="../../assets/img/screenshots/faculty.PNG" alt="">

        <p>
       Storing the faculty details like basic details, photos, docs, etc. </p>

      </section>

      <section class="sis-feature-desc-block">

        <h2>HR Dashboard</h2>

        <img src="../../assets/img/screenshots/hr.PNG" alt="">

        <p>
       A quick summary for the HR as shown in the above img. </p>

      </section>
      
      <section class="sis-feature-desc-block">

        <h2>ID card</h2>

        <img src="../../assets/img/screenshots/id.PNG" alt="">

        <p>
       User can generate the ID card based on the school requirements. </p>

      </section>
      
      <section class="sis-feature-desc-block">

        <h2>Payroll Modulde</h2>

        <img src="../../assets/img/screenshots/payroll.PNG" alt="">

        <p>
       Payroll module has the details of the payment to the staffs and payslips will be avvailable for each month. </p>

      </section>
    </div>
  </ng-container>

  <div class="about-valai">
    <h2>About valai</h2>

    <p>
      Each field in Valai School Management Software is built on Indian School needs. 
    </p>

    <p>
      
    </p>

    <p>

  </div>




</div>
